import styled from 'styled-components'
import { flexCenter, frameMedium, modifySize } from '../mixins'

export const StyledButton = styled.p`
  ${({ theme }) => `
      ${flexCenter()}
      ${frameMedium({ theme })}
      box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
      border: 2px solid ${theme['main-color']};
      font-size: ${modifySize(theme['font-size'], 1.3)};
      transition: ${theme['transition-time']};
      & :first-child {
        margin-right: ${theme['margin-small']};
      }
      &:hover {
        box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.16),
          0 0 0 1px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        background-color: ${theme['main-color']};
        color: ${theme['theme-color']};
        text-shadow: 4px 4px 25px ${theme['main-color']};
      }
    `};
`

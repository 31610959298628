import styled from 'styled-components'
import { flexCenter } from '../mixins'

export const Container = styled.div`
  ${({ theme }) => `
     ${flexCenter()}
     @media ${theme['mobile']} {
        flex-direction: column;
      }    
       `};
`

export const StyledImage = styled.div`
  ${({ theme }) => `
  max-width: 400px;
  width: 100%;
  margin: auto;   
       `};
`

export const Message = styled.div`
  ${({ theme }) => `
  margin: auto;
  text-align: center; 
       `};
`

/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
/* App imports */
import { StyledButton } from '../style/components/button'

const Button = ({ children, to, buttonStyle }) => (
  <Link to={to}>
    <StyledButton>{children}</StyledButton>
  </Link>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
}

Button.defaultProps = {
  buttonStyle: '',
}

export default Button
